.progress-container {
    display: flex;
    justify-content: space-evenly;

    .progress-sub-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;

        .progress-item {
            background-color: #E6EBED;
            width: 100%;
            height: 10px;
            border-radius: 10px;
        }
        .progress-item-active {
            background-color: #DE8646;
            width: 100%;
            height: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    }
}




