.main-content {
    display: flex;
    padding: 0px;
    text-align: center;
    position: absolute;
    top: 200px;
    left: 0px;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  
}

.prevStep {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    // width: 100%;
    height: 40px;
    background-color: #DE8646;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 20px;
}

.nextStep {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    // width: 100%;
    height: 40px;
    background-color: #DE8646;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 20px;
}

// .MuiButton-contained2 {
//     text-decoration: underline!important;
//    }